import Form from './Form';

const LogIn = () => {
    return (
        <section className="log_in">
            <h1>Log-In</h1>
            <Form/>
        </section>
    );
}
 
export default LogIn;