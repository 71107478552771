import Form from './Form';

const SignUp = () => {
    return (
        <section className="sign_up">
            <h1>Sign-Up</h1>
            <Form/>
        </section>
    );
}
 
export default SignUp;